<template>
  <div class="page set-page">
    <BreadcrumbsComponent :links="links" :title="data.title" />
    <div class="container-padding">
      <div v-if="data && data.title" class="page__inner set-page__inner">
        <SetComponent :data="data" />
        <PromocodeComponent
          v-if="data.advantages && data.advantages.length"
          :advantages="data.advantages"
          hide-promo
        />
        <ProductsListComponent :items="set_products" title="Что входит в набор" wrapper />
        <BannersSliderComponent :items="banners" />
        <ProductsSliderComponent :items="data.match_well" title="С этим хорошо сочетается" />
      </div>
      <h2 v-else>В данном наборе отсутствует товары</h2>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SetComponent from "../components/SetComponent.vue";
import PromocodeComponent from "components/PromocodeComponent.vue";
import ProductsListComponent from "components/ProductsListComponent.vue";
import BannersSliderComponent from "components/slider/BannersSliderComponent.vue";
import ProductsSliderComponent from "components/slider/ProductsSliderComponent.vue";
import SET_PAGE from "@/graphql/pages/SetPage.graphql";

export default {
  name: "SetPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: SET_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.state.set_page.sets_item = data.sets_item || [];
        store.state.set_page.banners = data.banners || [];
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      })
      .catch(() => {});
  },
  components: {
    ProductsSliderComponent,
    BannersSliderComponent,
    ProductsListComponent,
    PromocodeComponent,
    SetComponent,
    BreadcrumbsComponent,
  },
  computed: {
    data() {
      return this.$store.state.set_page.sets_item || {};
    },
    set_products() {
      return this.data?.products || [];
    },
    banners() {
      return this.$store.state.set_page.banners;
    },
    links() {
      return [{ title: "Каталог всех наборов", route: { name: "sets" } }];
    },
  },
  metaInfo() {
    return {
      title: this.data.meta_title || this.data.title,
      description: this.data.meta_description,
      keywords: this.data.meta_keywords,
    };
  },
};
</script>

<style lang="stylus">
.set-page {
  &__inner {
    gap: 50px
  }
}
</style>
