<template>
  <div class="set">
    <div class="set-video hover-image">
      <template v-if="data.video">
        <button v-if="showPreview" @click="play" class="set-video__preview">
          <ImgComponent class="set-video__preview-img" :img="data.img" width="800" height="600" />
          <IconComponent name="video-circle" />
          <span class="set-video__tag">Видео рецепт</span>
        </button>
      </template>
      <ImgComponent
        v-else-if="data.img"
        class="set-video__preview-img"
        :img="data.img"
        width="800"
        height="600"
      />
      <IframeComponent
        v-show="!showPreview"
        class="set-video__iframe"
        :class="{ 'set-video__iframe--visible': !showPreview }"
        v-if="data.video"
        id="set_video"
        :link="data.video | iframe_link"
      />
    </div>
    <div class="set-info">
      <div class="set-text">
        <span class="set-text__subtitle">{{ data.description }}</span>
      </div>
      <div class="set-content">
        <div class="set-content__prices">
          <span class="set-content__title">Цена набора:</span>
          <div class="set-content__numbers">
            <span class="set-content__number">{{ setSum | formatPrice }}</span>
            <span class="set-content__number--old" v-if="setSum < totalSum">
              {{ totalSum | formatPrice }}
            </span>
          </div>
        </div>
        <div class="set-content__buttons">
          <router-link v-if="inCart" :to="{ name: 'cart' }" class="btn btn--lg btn--main-outline">
            <IconComponent name="check" />
            <span>В корзине</span>
          </router-link>
          <button v-else class="btn btn--lg btn--green" @click="addCart">
            <IconComponent name="bag" />
            <span>В корзину</span>
          </button>
          <button v-if="data.recipe" @click="showRecipeModal" class="btn btn--main-outline btn--lg">
            <span>Читать рецепт</span>
          </button>
        </div>
      </div>
      <div v-if="data.calories && data.calories.length" class="set-calories">
        <span class="set-calories__title">На 100 граммов</span>
        <div class="set-calories__items">
          <div v-for="item in data.calories" :key="item.id" class="set-calories__item">
            <span class="set-calories__item-count">{{ item.value }}</span>
            <span class="set-calories__item-title">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import RecipeModal from "components/modals/components/RecipeModal.vue";
import IframeComponent from "components/IframeComponent.vue";
import { addCartItems } from "@/utils";

export default {
  name: "SetComponent",
  props: {
    data: Object,
  },
  data() {
    return {
      showPreview: true,
    };
  },
  computed: {
    // общая сумма цен товаров в наборе, не учитывая акции
    totalSum() {
      return this.data?.products.map((r) => r.price).reduce((a, b) => a + b, 0);
    },
    // общая сумма цен товаров в наборе, с учетом акций
    setSum() {
      return this.data?.products
        .map((r) => this.$options.filters.share_price(r.price, r))
        .reduce((a, b) => a + b, 0);
    },
    cartItems() {
      return this.$store.state.auth.cart;
    },
    inCart() {
      // если не нашлось ни одного продукта, которого нет в корзине
      // значит весь набор в корзине
      return !this.data?.products.some((p) => !this.cartItems.map((i) => i.item.id).includes(p.id));
    },
  },
  methods: {
    addCartItems,
    addCart() {
      if (!this.inCart) {
        this.addCartItems({
          store: this.$store,
          apollo: this.$apollo.provider.defaultClient,
          data: this.data?.products,
          count: 1,
        });
      }
    },
    showRecipeModal() {
      this.$store.state._modals.push({
        component: RecipeModal,
        options: {
          text: this.data.recipe,
        },
      });
    },
    play() {
      this.showPreview = false;
      let iframe = document.querySelector("#set_video iframe");
      iframe.setAttribute("src", iframe.src + "?feature=oembed&autoplay=1");
    },
  },
  components: { IframeComponent, IconComponent },
};
</script>

<style lang="stylus">
.set {
  display grid
  grid-template-columns 1fr 1fr
  gap 30px
  +below(990px) {
    grid-template-columns 1fr
  }
  +below(622px) {
    gap 15px
  }

  &-video {
    width 100%
    height auto
    border-radius var(--big-radius)
    overflow hidden
    position relative

    &__preview {
      background none
      border none
      cursor: pointer;

      &-img {
        width 100%
        height 100%
      }

      .icon {
        pointer-events none
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width 80px
        max-height 80px
        width 80px
        height 80px
      }
    }

    &__tag {
      absolute left 20px top 30px
      padding: 5px 10px;
      background: var(--white-o7);
      backdrop-filter: blur(5px);
      border-radius: 100px;
      color: var(--green-dark);
      font-size: 0.75em;
      line-height: 14px;
    }

    //&__iframe {
    //  opacity 0
    //  visibility hidden
    //  transition var(--transition)
    //
    //  &--visible {
    //    opacity 1
    //    visibility visible
    //  }
    //
    //  &:not(&--visible) {
    //    height 0
    //  }
    //}
  }

  &-info {
    max-width 635px
    display flex
    flex-direction column
    gap 30px
    +below(622px) {
      gap 15px
    }
  }

  &-text {
    display flex
    flex-direction column
    gap 20px

    &__title {
      margin-bottom 0
      font-weight 900
      line-height 34px
      +below(622px) {
        font-size 1.25em
        line-height 24px
      }
    }

    &__subtitle {
      font-size 1.125em
      line-height 28px
      +below(622px) {
        font-size 1em
        line-height 26px
      }
    }
  }

  &-content {
    display flex
    flex-direction column
    gap 20px

    &__prices {
      display flex
      flex-direction column
      gap 5px
    }

    &__title {
      font-size 0.875em
      line-height 20px
      color var(--dark-light)
      +below(622px) {
        font-size 0.75em
        line-height 14px
      }
    }

    &__numbers {
      display flex
      align-items center
      gap 10px
    }

    &__number {
      font-size 2em
      font-weight 700
      line-height 38px
      color var(--red)

      &--old {
        font-size 1.25em
        font-weight 400
        line-height 28px
        text-decoration-line line-through
        color var(--dark-light)
      }
    }

    &__buttons {
      display flex
      gap 15px
      +below(622px) {
        flex-direction column
      }
    }
  }

  &-calories {
    display flex
    flex-direction column
    gap 5px

    &__title {
      font-weight 700
      line-height 24px
    }

    &__items {
      display flex
      gap 5px
    }

    &__item {
      background var(--gray)
      border-radius 5px
      padding 5px
      display flex
      flex-direction column
      width 100%
      max-width 100px
      height 100%
      max-height 50px

      &-count {
        font-size 1.125em
        font-weight 700
        line-height 26px
      }

      &-title {
        font-size 0.75em
        line-height 14px
        color var(--dark-light)
      }
    }
  }
}
</style>
