<template>
  <div class="default-modal recipe-modal">
    <button class="default-modal__close" @click="$emit('closeAll')">
      <IconComponent name="close" />
    </button>
    <div class="default-modal__header">
      <h2 class="default-modal__title">Рецепт</h2>
    </div>
    <div class="default-modal__body">
      <EditorJSComponent v-if="$options.filters.editor_has_text(text)" :text="text" />
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
export default {
  name: "RecipeModal",
  props: {
    text: String,
  },
  components: { EditorJSComponent, IconComponent },
};
</script>

<style lang="stylus">
.recipe-modal {
  width 100%
  max-width 600px
  padding 30px

  &__body {
    padding-top 10px
  }
}
</style>
